@import url("https://fonts.googleapis.com/css?family=Nunito|Quicksand");
@import url("https://fonts.googleapis.com/css?family=Roboto|Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface|Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Francois+One|Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Notable&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin&family=Notable&display=swap");

* {
  padding: 0px;
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: unset;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

:root {
  --dominant-color: #faf8f2;
  --secondary-color: #60bea0;
  --background-color: white;
  --accent-color-1: #f2e592;
  --accent-color-2: #937e6a;
  --accent-color-3: #d1c8c0;
}

.homepage-outer-container {
  display: flex;
  position: relative;
  justify-content: center;
}

.yellow-circle {
  position: absolute;
  display: flex;
  height: 25rem;
  width: 25rem;
  border-radius: 50%;
  animation: transitionIn 2500ms;
}

.yellow-circle {
  background-color: var(--accent-color-1);
  right: -6rem;
  top: -15rem;
  z-index: 5;
}

.overlay,
.overlay-calendar,
.overlay-calendar-2,
.over-column-container {
  display: flex;
  height: 88vh;
}

.over-column-container {
  display: flex;
  position: relative;
  width: 95%;
  left: 3.1rem;
}

.overlay {
  background-color: var(--accent-color-1);
  bottom: 0.5rem;
  z-index: -11;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.overlay-calendar {
  background-color: var(--dominant-color);
  width: 94%;
  position: absolute;
}

.overlay-calendar-2 {
  background-color: var(--dominant-color);
  width: 95%;
  left: 3.1rem;
  position: absolute;
}

.homepage-container {
  display: flex;
  position: relative;
  height: 88vh;
  top: 0;
  bottom: 0.5rem;
  justify-content: space-around;
  align-items: center;
  animation: expand 800ms ease forwards;
  background-color: var(--dominant-color);
  position: relative;
  transition: all 800ms ease;
  width: 94%;
}

.container-content {
  width: 90%;
  flex: 1;
}

.container-content-inner {
  flex: 1;
  width: 90%;
  margin-left: 5rem;
}

.paragraph-container {
  height: auto;
  overflow: hidden;
  position: relative;
}

.homepage-paragraph {
  line-height: 2.8rem;
  transform: translateY(35rem);
  color: #343534;
  font-size: 1.6rem;
  font-family: "Open Sans Condensed", sans-serif;
  animation: slideUp 800ms ease-in-out forwards 800ms;
  text-align: justify;
}

.start-word {
  /* font-weight: bolder; */
  font-size: 1.6rem;
  font-weight: bolder;
  color: black;
  font-family: "Open Sans Condensed", sans-serif;
}

.homepage-button {
  height: 100%;
  position: relative;
  width: 15rem;
  overflow: hidden;
}

.button-to-calendar {
  background-color: var(--accent-color-3);
  border: 0.1rem solid var(--accent-color-3);
  border-radius: 1rem;
  width: 10rem;
  height: 4rem;
  margin-top: 1.5rem;
  transform: translateY(5rem);
  cursor: pointer;
  position: relative;
  animation: slideUp 1500ms ease-in-out forwards;
}

a.button-to-home {
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  outline: none;
  font-family: "Open Sans Condensed", sans-serif;
  color: black;
  cursor: pointer;
  font-weight: bolder;
  font-size: 1.6rem;
}

.button-to-calendar:hover {
  font-weight: bolder;
  background-color: var(--accent-color-1);
  border: 0.1rem solid var(--accent-color-1);
  color: black;
}

.homepage-title {
  overflow: hidden;
  height: auto;
}

.veg-chal {
  font-size: 6rem;
  margin-bottom: 2rem;
  transform: translateY(20rem);
  animation: slideUp 800ms ease forwards 500ms;
  color: black;
  font-family: "Amatic SC", cursive;
  font-weight: bolder;
}

.homepage-image-container {
  flex: 1;
  margin: 5rem;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-image {
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avacado-image {
  width: 10%;
  flex: 1 1 4rem;
  animation: slideIn 1500ms ease-in-out forwards;
}

.avacado-image:hover {
  opacity: 0.9;
}

@keyframes slideIn {
  0% {
    transform: translateX(50rem) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(35rem);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes expand {
  0% {
    transform: translateX(140rem);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes transitionIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

input {
  display: none;
}

.open,
.newMenu {
  background-color: #3c3c3c;
  width: 2.4rem;
  height: 0.4rem;
  display: block;
  cursor: pointer;
  position: relative;
  top: 8px;
}

.open:before,
.newMenu {
  content: "";
  background-color: #3c3c3c;
  width: 2.4rem;
  height: 0.4rem;
  display: block;
  border-radius: 0.2rem;
  position: relative;
  top: -0.8rem;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.open:after,
.newMenu {
  content: "";
  background-color: #3c3c3c;
  width: 2.4rem;
  height: 0.4rem;
  display: block;
  border-radius: 0.2rem;
  position: relative;
  top: 0.4rem;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.menuOpen {
  height: 2rem;
  margin: 0rem 3.1rem 0rem 0rem;
  display: block;
  cursor: pointer;
}

.menuOpen:hover .open:before {
  top: -0.9rem;
}

.menuOpen:hover .open:after {
  top: 0.5rem;
}

.menu {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #f2e592;
  z-index: 9;
}

.menu label {
  position: absolute;
  background-size: 100%;
  cursor: pointer;
}

.menu .menuContent {
  position: relative;
  text-align: center;
  flex: 1;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.menu ul li a {
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 9rem;
  font-family: Trebuchet;
  text-transform: uppercase;
  padding: 2.5rem 0;
  font-family: "Amatic SC", cursive;
}

.menu ul li a:hover {
  color: black;
}

.menu ul li:hover {
  background-color: #faf8f2;
  transition-duration: 500ms;
}

.menuEffects {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}

.menuEffects ul {
  transform: translateY(0%);
  transition: all 0.5s;
}

#menuToggle,
.menuEffects {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

#menuToggle:checked ~ .menuEffects ul {
  opacity: 1;
}

#menuToggle:checked ~ .menuOpen .open {
  background-color: transparent;
}

#menuToggle:checked ~ .menuOpen .open:before {
  content: "";
  background-color: black;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

#menuToggle:checked ~ .menuOpen .open:after {
  content: "";
  background-color: black;
  transform: rotate(-45deg);
  position: relative;
  top: 0;
  right: 0;
  z-index: 3;
}

#menuToggle:not(:checked) ~ .menuEffects ul {
  transform: translateY(-30%);
}

.hamburger-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  margin: 0rem;
  height: 6vh;
}

.vvc-logo {
  position: relative;
  flex: 1;
}

.brand-logo {
  height: 4vh;
  margin-left: 4rem;
}

.hamburger {
  flex: 1;
  padding: 0.3rem;
  font-size: 3rem;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.line {
  flex: 1;
  width: 3rem;
  height: 0.3rem;
  margin: 0.5rem;
  background: black;
}

.x {
  margin-left: 0.8rem;
}

/* .transition-outer-container {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
} */

.transition-calendar-container {
  animation: transitionIn 500ms;
  background-color: var(--secondary-color);
  height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.title-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 95%;
}

.thirty-day-challenge {
  text-align: center;
  font-family: "Amatic SC", cursive;
  font-weight: bolder;
  font-size: 6rem;
  /* padding: 0.5rem; */
  position: relative;
  flex: 1;
}

.pick-calendar-day {
  margin-top: 2rem;
  text-align: center;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 2rem;
  padding: 0.5rem;
  margin: 0.5rem;
  position: relative;
  flex: 1;
}

.challenge-container {
  display: flex;
  flex-flow: row wrap;
  /* flex-basis: 100%; */
  /* padding: 1rem;
  padding-left: 0.667rem; */
  border-right: solid 0rem white;
  border-bottom: solid 0rem white;
  width: 95%;
  position: relative;
  flex: 1;
}

.challenge-container > div {
  flex: 1 0 17%;
}
.challenge-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.challenge-divs {
  flex: 1;
  text-align: center;
  position: relative;
  bottom: 1rem;
  justify-content: space-evenly;
  border-left: solid var(--secondary-color);
  border-top: solid var(--secondary-color);
  background: var(--dominant-color);
  padding: 2rem 0.5rem;
}

.challenge-divs {
  flex: 1;
  text-align: center;
  position: relative;
  bottom: 1rem;
  justify-content: space-evenly;
  border-left: solid var(--secondary-color);
  border-top: solid var(--secondary-color);
  background: var(--dominant-color);
  padding: 2rem 0.5rem;
}

.challenge-divs:hover {
  background-color: var(--accent-color-1);
  transition-duration: 500ms;
  cursor: pointer;
}

.challenge-items {
  color: #2d3748;
  text-decoration: none;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: bolder;
  margin-bottom: 0rem;
  font-size: 1.6rem;
}

.challenge-items:hover {
  color: black;
}

.challenge-items > div {
  margin: 0px;
}

a:hover {
  text-decoration: none;
}

.progress-days {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 3.5rem;
  flex-flow: column wrap;
  position: relative;
  width: 95%;
  flex: 1;
  bottom: 1rem;
}

.challenge-day-progress {
  font-size: 2rem;
  font-family: "Open Sans Condensed", sans-serif;
  text-align: center;
  font-weight: bold;
}

.challenge-number {
  position: relative;
  font-size: 5rem;
  font-family: "Amatic SC", cursive;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.progress-path {
  position: relative;
  align-items: center;
  /* bottom: 8rem; */
  height: 2rem;
  background-color: #e0f3ed;
  border-radius: 100rem 100rem;
}

.progress-stroke {
  display: flex;
  align-items: center;
  position: absolute;
  font-weight: bolder;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.2rem;
  height: 2rem;
  background-color: var(--accent-color-1);
  border-radius: 100rem 100rem;
}

/* left column */

.progress-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dominant-color);
}

.right-column {
  position: relative;
  /* margin-top: 23rem; */
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 92%;
}

.left-column {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
}

.menu-container {
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  top: 2rem;
}

.breakfast-container,
.lunch-container,
.dinner-container,
.brown-b-c,
.brown-l-c,
.brown-d-c {
  padding: 0.5rem;
  flex: 1;
  height: 5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.brown-l-c,
.brown-d-c {
  background-color: var(--accent-color-2);
  color: white;
}

.breakfast-container {
  background-color: var(--accent-color-2);
  margin-left: 1rem;
  color: white;
}

.brown-b-c {
  margin-left: 1rem;
  background-color: var(--accent-color-3);
}

.breakfast-container:hover,
.lunch-container:hover,
.dinner-container:hover,
.brown-b-c:hover,
.brown-l-c:hover,
.brown-d-c:hover {
  background-color: var(--accent-color-2);
  color: white;
}

.lunch-container {
  background-color: var(--accent-color-3);
  margin: 0rem 1rem;
}

.brown-l-c {
  margin: 0rem 1rem;
}

.dinner-container {
  background-color: var(--accent-color-3);
  margin-right: 1rem;
}

.brown-d-c {
  margin-right: 1rem;
}

.breakfast-item,
.lunch-item,
.dinner-item {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1rem;
  font-weight: bolder;
}

.breakfast-details,
.lunch-details,
.dinner-details {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.6rem;
  text-align: center;
}

a.recipe-button {
  font-family: "Open Sans Condensed", sans-serif;
  padding: 0.6rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  color: black;
}

.recipe-button:hover {
  cursor: pointer;
  font-weight: bolder;
  color: var(--accent-color-2);
}

.meal-name {
  font-family: "Amatic SC", cursive;
  font-size: 3rem;
  text-align: center;
}

.menu-items {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
}

.recipes,
.recipes-breakfast {
  height: 32rem;
  width: 97%;
  margin-top: 1rem;
  display: flex;
  position: relative;
}

.breakfast-image-container,
.lunch-image-container,
.dinner-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.breakfast-content,
.lunch-content,
.dinner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.breakfast-img,
.lunch-img,
.dinner-img {
  max-height: 27.5rem;
  padding: 1rem;
  flex: 1;
  border-radius: 10rem 1rem 10rem 10rem;
}

.cowspiracy-outer-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.cowspiracy-container {
  display: flex;
  height: 88vh;
  background-color: var(--dominant-color);
  width: 95%;
}

.all-content {
  display: flex;
}

.resource-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.resource-title {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 4rem;
}

.resource-paragraph {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
  line-height: 3.5rem;
  margin-top: 1rem;
  width: 90%;
}

.resource-button {
  font-size: 1.3rem;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
  text-transform: uppercase;
  color: black;
  font-family: "Open Sans Condensed", sans-serif;
}

.resource-button:hover {
  color: var(--accent-color-2);
  font-weight: bolder;
}

.arrow-left,
.arrow-right {
  flex: 1;
}

.image-resource-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.image-resource-container {
  flex: 3;
}

.doc-image {
  max-width: 80%;
}

.resource-content-container {
  flex: 4;
}

.arrow-left,
.arrow-right {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.fa-arrow-left,
.fa-arrow-right {
  flex: 1;
  cursor: pointer;
  font-size: 3.5rem;
  color: var(--secondary-color);
}
.fa-arrow-left:hover,
.fa-arrow-right:hover {
  color: var(--accent-color-1);
}

.contact-us-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.contact-us-container {
  display: flex;
  height: 88vh;
  width: 95%;
  background-color: var(--dominant-color);
  justify-content: space-between;
  align-items: center;
}

.contact-left-column,
.contact-right-column {
  flex: 1;
}

.contact-left-column {
  margin-left: 9rem;
}

.dont-be-shy,
.highlight-hi {
  font-family: "Amatic SC", cursive;
  font-size: 8rem;
  position: absolute;
  top: 8rem;
  z-index: 7;
}

.highlight-hi {
  color: var(--secondary-color);
  position: relative;
  top: 0rem;
}

.contact-information,
.contact-information-2 {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 2rem;
  line-height: 4rem;
  position: relative;
  z-index: 4;
}

.contact-information-2 {
  font-weight: bolder;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}
.personal-information {
  display: flex;
  position: relative;
  z-index: 4;
}

.fa-envelope,
.fa-instagram {
  font-size: 2rem;
  color: black;
  cursor: pointer;
}

.fa-envelope:hover,
.fa-instagram:hover {
  color: var(--accent-color-2);
}

.fa-instagram {
  margin-left: 1rem;
}

.shanice-image-container {
  display: flex;
  justify-content: center;
}
.shanice-image {
  width: 60%;
  border-radius: 100rem 5rem 100rem 100rem;
}
.contact-container {
  display: flex;
  position: relative;
  height: 6vh;
  align-items: center;
}

.help {
  font-family: "Amatic SC", cursive;
  font-size: 2rem;
  color: black;
  margin-bottom: 0rem;
}
.contact-column-right,
.contact-column-left {
  flex: 1;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.contact-column-left {
  position: relative;
  margin-left: 4.3rem;
}

.contact-column-right {
  position: relative;
  justify-content: flex-end;
  margin-right: 4.3rem;
}

.blue-circle {
  position: absolute;
  background-color: var(--secondary-color);
  display: flex;
  bottom: 1.5rem;
  left: -8rem;
  height: 25rem;
  width: 25rem;
  border-radius: 50%;
}

.fa-copyright {
  font-size: 1.5rem;
}

.email {
  color: black;
  font-family: "Amatic SC", cursive;
  font-size: 2rem;
}

.email:hover,
.help:hover {
  font-weight: bolder;
  color: var(--accent-color-1);
}

.email-container {
  display: flex;
  flex-flow: row wrap;
}

.email-address {
  margin-bottom: 0rem;
}

/* mobile */
@media (max-width: 768px) {
  .menu {
    width: 100%;
    height: 100%;
  }

  .brand-logo {
    margin-left: 1rem;
  }

  .yellow-circle {
    height: 22rem;
    width: 22rem;
  }

  .container-image,
  .homepage-image-container {
    display: none;
  }

  .container-content {
    flex: unset;
  }

  .container-content-inner {
    margin-left: 0rem;
    width: 100%;
  }

  a.button-to-home {
    font-size: 1.5rem;
  }

  .veg-chal {
    font-weight: bold;
    font-size: 4rem;
  }

  .menu ul li a {
    font-size: 7rem;
  }

  .title-page-container {
    justify-content: center;
    flex: unset;
    position: relative;
    top: 2.5rem;
  }
  .thirty-day-challenge {
    font-size: 4rem;
    font-weight: bolder;
    z-index: 5;
    flex: unset;
  }

  .pick-calendar-day {
    margin: unset;
    flex: unset;
  }

  .challenge-container {
    padding-top: 1rem;
    align-content: center;
  }

  .transition-calendar-container {
    height: 93vh;
  }

  .progress-days {
    flex: unset;
    position: relative;
    bottom: 2.5rem;
  }

  .help,
  .email {
    font-size: 1.6rem;
  }

  .contact-container {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .contact-column-left {
    flex: 1;
    display: unset;
    margin: 0rem;
  }

  .contact-column-right {
    margin-right: 0rem;
  }

  .overlay-calendar-2 {
    width: 100%;
    left: 0rem;
    display: flex;
    align-items: center;
  }

  .over-column-container {
    flex-direction: column;
    left: 0rem;
    width: 100%;
    align-items: center;
  }

  .overlay,
  .overlay-calendar,
  .over-column-container {
    height: 190vh;
  }

  .overlay-calendar-2 {
    height: auto;
  }

  .overlay-calendar {
    display: none;
  }

  .left-column {
    width: 95%;
    display: flex;
    flex-direction: column;
    flex: unset;
  }

  .right-column {
    width: 100%;
    height: 60vh;
    margin-top: unset;
    top: 1rem;
  }

  .challenge-number,
  .menu-container {
    top: 0;
  }

  .challenge-number {
    flex: unset;
    margin-bottom: 0rem;
  }

  .menu-container {
    flex: unset;
  }

  .menu-items {
    align-items: center;
    bottom: 0;
  }

  .recipes,
  .recipes-breakfast {
    flex-direction: column;
    width: 100%;
    height: unset;
  }

  .breakfast-item,
  .lunch-item,
  .dinner-item {
    font-size: 2rem;
  }

  .breakfast-img,
  .lunch-img,
  .dinner-img {
    max-width: 50%;
    max-height: none;
  }

  .breakfast-image-container,
  .lunch-image-container,
  .dinner-image-container {
    flex: 1;
  }

  .breakfast-content,
  .lunch-content,
  .dinner-content {
    flex: 1;
  }

  .all-content {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .resource-content-container {
    flex: unset;
  }

  .resource-paragraph {
    line-height: 2.2rem;
    font-size: 1.4rem;
    text-align: center;
    width: unset;
    margin: unset;
  }

  .resource-title {
    font-size: 3rem;
  }

  .arrow-left,
  .arrow-right {
    padding: 0;
  }

  .image-resource-container {
    flex: unset;
    width: 20rem;
    height: 20rem;
  }

  .doc-image {
    max-width: 60%;
    position: relative;
    z-index: 6;
  }

  .contact-left-column {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .contact-right-column {
    display: none;
  }

  .dont-be-shy,
  .highlight-hi {
    position: relative;
    top: 0;
    font-size: 4rem;
    padding-bottom: 2rem;
  }

  .contact-information-2 {
    margin: 0rem;
  }

  .contact-information,
  .contact-information-2 {
    font-size: 1.6rem;
  }
}
